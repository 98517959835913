<template>
  <el-dialog
    v-drag
    :title="dialogType ? '新增账单-一般' : '新增账单-分摊'"
    :visible.sync="dialogVisible"
    :width="dialogType ? '800px' : '1026px'"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="5%"
  >
    <div :class="['content', dialogType ? 'calc' : '']">
      <template v-if="!dialogType">
        <div class="header">
          <div class="form-item">
            <label
              class="lable"
            ><span class="red">* </span>导入分摊明细文件</label
            >
            <el-upload
              action="#"
              :show-file-list="false"
              :file-list="fileList1"
              :http-request="httpRequest"
            >
              <div class="upload flex_c_c">
                <img src="@/assets/images/bill/upload-1.png" />
                选择文件上传
              </div>
            </el-upload>
          </div>
          <div class="form-item">
            <label class="lable"><span class="red">* </span>账单日期</label>
            <el-date-picker
              v-model="invoiceDate"
              type="date"
              style="width: 320px"
              placeholder="请选择日期"
            >
            </el-date-picker>
          </div>
          <div class="form-item">
            <label class="lable"><span class="red">* </span>上传账单文件</label>
            <el-upload
              action="#"
              :show-file-list="false"
              :file-list="fileList2"
              :http-request="(e) => httpRequest2(e, 0)"
            >
              <div class="upload flex_c_c">
                <img src="@/assets/images/bill/upload-1.png" />
                选择文件上传
              </div>
            </el-upload>
          </div>
          <div
            :style="{
              visibility:
                fileList1[0] && fileList1[0].fileName ? 'visible' : 'hidden',
            }"
            class="fileList"
          >
            <div style="flex: 1; display: flex; align-items: center">
              <div class="flex_c_c">
                <el-progress
                  type="circle"
                  :width="40"
                  :height="40"
                  :stroke-width="4"
                  :percentage="porgress1[0]"
                ></el-progress>
              </div>
              <div class="file">
                <el-tooltip
                  ref="tlp"
                  :content="fileList1[0]?.fileName"
                  effect="dark"
                  :disabled="!tooltipFlag"
                  placement="bottom"
                >
                  <p class="fileName" @mouseenter="visibilityChange($event)">{{ fileList1[0]?.fileName }}</p>
                </el-tooltip>
                <p class="fileSize">{{ fileList1[0]?.fileSize }} mb</p>
              </div>
            </div>
          </div>
          <div class="other">
            <div class="tip">
              <i class="el-icon-warning-outline"></i>
              <span>分摊不支持账单识别</span>
            </div>
            <div class="download flex_c_c" @click="download">
              <img src="@/assets/images/order/download.png">
              下载分摊明细模版
            </div>
          </div>
        </div>
        <div class="data">
          <BaseTable
            :height="280"
            :tableData="tableData"
            :loading="loading"
            :isPager="false"
            :border="true"
          >
            <div slot="custom-total" class="customTotal flex_c_c">
              总金额：<span>{{ total }} USD</span>
            </div>
          </BaseTable>
        </div>
      </template>
      <template v-else>
        <el-form ref="form" :model="form" :rules="rules" label-position="top">
          <el-row>
            <el-col :span="12">
              <el-form-item label="账单号码" prop="invoiceNumber">
                <el-input
                  v-model="form.invoiceNumber"
                  placeholder="请输入账单号码"
                  style="width: 320px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="供应商类型" prop="supplierTypeId">
                <el-select
                  v-model="form.supplierTypeId"
                  filterable
                  style="width: 320px"
                  placeholder="请选择供应商类型"
                >
                  <el-option
                    v-for="item in supplierTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    @click.native="supplierType(item.dbValue)"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="供应商" prop="supplierId">
                <el-select
                  v-model="form.supplierId"
                  style="width: 320px"
                  placeholder="请选择供应商"
                  filterable
                >
                  <el-option
                    v-for="item in supplierOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="账单日期" prop="invoiceDate">
                <el-date-picker
                  v-model="form.invoiceDate"
                  type="date"
                  style="width: 320px"
                  placeholder="请选择"
                >
                </el-date-picker>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="账单金额" prop="invoiceAmount">
                <el-input
                  v-model="form.invoiceAmount"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  placeholder="请输入金额"
                  style="width: 320px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <!-- <el-col :span="12">
              <el-form-item label="账单金额" prop="invoiceAmount">
                <el-input
                  v-model="form.invoiceAmount"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  placeholder="请输入金额"
                  style="width: 320px"
                ></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="币制" prop="currency">
                <el-select
                  v-model="form.currency"
                  style="width: 320px"
                  placeholder="请选择"
                >
                  <el-option label="USD" value="USD"></el-option>
                  <el-option label="RMB" value="RMB"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="柜号" prop="containerNumber">
                <el-input
                  v-model="form.containerNumber"
                  disabled
                  style="width: 320px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item prop="blNo">
                <span slot="label" class="label">
                  提单号<span>(输入提单/柜号；如有多条分单必须选中一条)</span>
                </span>
                <el-tooltip
                  v-model="showTip"
                  popper-class="tooltip"
                  :manual="true"
                  effect="dark"
                  placement="top-end"
                >
                  <el-input
                    v-model="form.blNo"
                    filterable
                    style="width: 320px"
                    placeholder="请输入提单号"
                  >
                  </el-input>
                  <template slot="content">
                    <div class="data">
                      <BaseTable
                        height="240"
                        :tableData="tableData2"
                        :loading="loading2"
                        :isPager="false"
                        @handlerRadio="handlerRadio"
                      ></BaseTable>
                    </div>
                    <div class="footer flex_c_c">
                      <div class="cancel flex_c_c" @click="showTip = false">取消</div>
                      <div class="confirm flex_c_c" @click="confirm">确定</div>
                    </div>
                  </template>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <div class="form-item">
                <label class="lable"><span class="red">* </span>上传账单文件</label>
                <el-upload
                  class="upload-demo flex_c_c"
                  action="#"
                  :show-file-list="false"
                  :file-list="fileList2"
                  :http-request="(e) => httpRequest2(e, 1)"
                >
                  <div class="upload flex_c_c">
                    <img src="@/assets/images/bill/upload-1.png" />
                    选择文件上传
                  </div>
                </el-upload>
                <div :style="{'visibility':fileList2[0] && fileList2[0].fileName ? 'visible' : 'hidden'}" class="fileList2 flex_c_c">
                  <div style="flex: 1; display: flex; align-items: center">
                    <div class="flex_c_c">
                      <el-progress
                        type="circle"
                        :width="40"
                        :height="40"
                        :stroke-width="4"
                        :percentage="porgress2[0]"
                      ></el-progress>
                    </div>
                    <div class="file">
                      <p class="fileName2">{{ fileList2[0]?.fileName }}</p>
                      <p class="fileSize2">{{ fileList2[0]?.fileSize }} mb</p>
                    </div>
                  </div>
                  <div class="flex_c_c">
                    <i class="el-icon-delete icon" title="删除" @click="deleteFile" />
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </template>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div class="ok flex_c_c" @click="ok">确定新增</div>
    </span>
  </el-dialog>
</template>

<script>
import * as XLSX from 'xlsx'
const BaseTable = () => import('@/components/table/BaseTable.vue')
import { createSharePayableInvoice, createPayable } from '@/api/fee'
import { existsMBL } from '@/api/bill'
import { getSysParams, getSupplierDropDownList } from '@/api/system'
import config from '@/utils/config'
import { debounce } from '@/utils/common'
export default {
  components: { BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dialogType: {
      type: Number,
      default: 0
    }
  },
  data() {
    const validateBill = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入提单号'))
      } else {
        this.checkBill(value, callback)
      }
    }
    return {
      loading: false,
      loading2: false,
      tableData: {
        fileds: [
          {
            filed: 'invoiceNumber',
            label: '发票号码',
            width: '120',
            resizable: true
          },
          {
            filed: 'blNo',
            label: '提单号码',
            width: '120',
            resizable: true
          },
          {
            filed: 'supplierTypeDes',
            label: '供应商类型',
            width: '100',
            resizable: true
          },
          {
            filed: 'supplierCode',
            label: '供应商代码',
            width: '100',
            resizable: true
          },
          {
            filed: 'feeNameCode',
            label: '费用代码',
            width: '100',
            resizable: true
          },
          {
            filed: 'unitPrice',
            label: '单价',
            width: '100',
            resizable: true
          },
          {
            filed: 'currency',
            label: '币种',
            width: '100',
            resizable: true
          },
          {
            filed: 'qty',
            label: '数量',
            width: '100',
            resizable: true
          },
          {
            filed: 'remark',
            label: '备注',
            resizable: true
          }
        ],
        columns: []
      },
      tableData2: {
        fileds: [
          {
            filed: '',
            label: '创建日期',
            width: '120'
          },
          {
            filed: 'blNo',
            label: '主提单号',
            width: '117'
          },
          {
            filed: 'blNo',
            label: '分单号',
            width: '117'
          },
          {
            filed: 'containerNumber',
            label: '柜号',
            width: '117'
          },
          {
            filed: 'companyName',
            label: '客户',
            width: '117'
          }
        ],
        columns: [],
        showRadio: true
      },
      form: {
        invoiceNumber: '',
        supplierTypeId: '',
        supplierId: '',
        invoiceDate: '',
        invoiceAmount: '',
        currency: 'USD',
        containerNumber: '',
        mblId: '',
        blNo: ''
      },
      rules: {
        invoiceNumber: [
          { required: true, message: '请输入账单号码', trigger: 'blur' }
        ],
        supplierTypeId: [
          { required: true, message: '请选择供应商类型', trigger: 'change' }
        ],
        supplierId: [
          { required: true, message: '请选择供应商', trigger: 'change' }
        ],
        invoiceDate: [
          { required: true, message: '请选择账单日期', trigger: 'blur' }
        ],
        invoiceAmount: [
          { required: true, message: '请输入金额', trigger: 'blur' }
        ],
        currency: [
          { required: true, message: '请选择币制', trigger: 'change' }
        ],
        blNo: [{ required: true, validator: validateBill, trigger: 'change' }]
      },
      supplierOptions: [],
      supplierTypeOptions: [],
      fileList1: [],
      fileList2: [],
      porgress1: [],
      porgress2: [],
      invoiceDate: '',
      uploadFile: null,
      formData: new FormData(),
      showTip: false,
      radioObj: {},
      tooltipFlag: false
    }
  },
  computed: {
    total() {
      const total = this.tableData.columns.reduce((prev, next) => {
        return prev + next.unitPrice
      }, 0)
      return total
    }
  },
  mounted() {
    this.getCommTypeData()
    this.form.invoiceDate = new Date()
  },
  methods: {
    // 供应商类型
    getCommTypeData() {
      getSysParams({ type: 12 }).then((res) => {
        if (res.success) {
          const arr = []
          res.data &&
            res.data[0].value &&
            res.data[0].value.map((item) => {
              arr.push({
                label: item.description,
                value: item.id,
                dbValue: item.dbValue
              })
            })
          this.supplierTypeOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    // 供应商
    getCommData(scopValue) {
      getSupplierDropDownList({ scopValue }).then((res) => {
        if (res.success) {
          res.data && (this.supplierOptions = res.data.map(a => {
            return {
              label: `${a.code}-${a.name}`,
              value: a.id
            }
          }))
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    cancel() {
      this.showTip = false
      this.reset()
      this.$emit('cancel')
    },
    reset() {
      this.tableData.columns = []
      this.fileList1 = []
      this.fileList2 = []
      this.porgress1 = []
      this.porgress2 = []
      this.supplierOptions = []
      this.invoiceDate = ''
      this.uploadFile = null
      this.formData = new FormData()
      this.dialogType && this.$refs['form'].resetFields()
      this.form.invoiceDate = new Date()
    },
    async ok() {
      if (!this.uploadFile) {
        this.$message.warning('请上传账单文件')
        return
      }
      this.formData.set('file', this.uploadFile)
      if (!this.dialogType) {
        if (!this.invoiceDate || !this.uploadFile) {
          this.$message.warning('请填写完整信息')
          return
        }
        if (this.tableData.columns.length < 2) {
          this.$message.warning('最少导入两条数据')
          return
        }
        this.tableData.columns.forEach((a, i) => {
          this.formData.set(`input.data[${i}][blNo]`, a.blNo)
          this.formData.set(`input.data[${i}][invoiceNumber]`, a.invoiceNumber)
          this.formData.set(`input.data[${i}][supplierTypeDes]`, a.supplierTypeDes)
          this.formData.set(`input.data[${i}][supplierCode]`, a.supplierCode)
          this.formData.set(`input.data[${i}][feeNameCode]`, a.feeNameCode)
          this.formData.set(`input.data[${i}][unitPrice]`, a.unitPrice)
          this.formData.set(`input.data[${i}][currency]`, a.currency)
          this.formData.set(`input.data[${i}][qty]`, a.qty)
          this.formData.set(`input.data[${i}][remark]`, a.remark ? a.remark : '')
        })
        this.formData.set('input.invoiceDate', this.formatTime(this.invoiceDate))
        try {
          const result = await createSharePayableInvoice(this.formData)
          if (result.data.success) {
            this.reset()
            this.$emit('ok')
          } else {
            this.$message.error(result.data.errorMessage)
          }
        } catch (error) {
          console.error(error)
        }
      } else {
        this.$refs['form'].validate(async(valid) => {
          if (valid) {
            this.formData.set('input.blNo', this.form.blNo)
            this.formData.set('input.mblId', this.form.mblId)
            this.formData.set('input.invoiceNumber', this.form.invoiceNumber)
            this.formData.set('input.invoiceDate', this.formatTime(this.form.invoiceDate))
            this.formData.set('input.invoiceAmount', this.form.invoiceAmount)
            this.formData.set('input.supplierId', this.form.supplierId)
            this.formData.set('input.currency', this.form.currency)
            this.formData.set('input.supplierTypeId', this.form.supplierTypeId)
            try {
              const result = await createPayable(this.formData)
              if (result.data.success) {
                this.reset()
                this.$emit('ok')
              } else {
                this.$message.error(result.data.errorMessage)
              }
            } catch (error) {
              console.error(error)
            }
          } else {
            return false
          }
        })
      }
    },
    httpRequest({ file }) {
      const suffix = file.name.slice(
        file.name.lastIndexOf('.') + 1,
        file.name.length
      )
      if (suffix !== 'xlsx') {
        this.$message.warning('请上传xlsx文件')
        return
      }
      const reader = new FileReader()
      const that = this
      that.loading = true
      reader.onload = async function(e) {
        const data = e.target.result
        const wb = XLSX.read(data, { type: 'binary' })
        const jdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
        const newData = jdata.map((a) => {
          const newKey = Object.keys(a).sort()
          const newObj = {}
          newKey.forEach((b, i) => {
            newObj[newKey[i].toLowerCase()] = a[newKey[i]]
          })
          return newObj
        })
        .map(a => {
          return {
            invoiceNumber: a['invoice number'],
            blNo: a.mblno,
            supplierTypeDes: a['supplier type des'],
            supplierCode: a['supplier code'],
            feeNameCode: a['feename code'],
            unitPrice: a['unit price'],
            currency: a.currency,
            qty: a.qty,
            remark: a.remark
          }
        })
        if (
          newData.some(
            (a) => a.invoiceNumber !== newData[0].invoiceNumber
          )
        ) {
          that.$message.error('发票号码每一行都需要一致')
          that.loading = false
          return
        }
        that.loading = false
        that.tableData.columns = newData
      }
      reader.readAsBinaryString(file)
    },
    httpRequest2(e, index) {
      const file = e.file
      const suffix = file.name.slice(
        file.name.lastIndexOf('.') + 1,
        file.name.length
      )
      if (suffix !== 'pdf') {
        this.$message.warning('请上传pdf文件')
        return
      }
      this.uploadFile = file
      switch (index) {
        case 0:
          if (this.fileList1.length === 0) {
            this.fileList1.push([])
          }
          this.fileList1.splice(0, this.fileList1[0] ? 1 : 0, {
            fileName: file.name,
            fileSize: (file.size / 1024 / 1024).toFixed(2)
          })
          if (this.porgress1.length === 0) {
            this.porgress1.push([])
          }
          this.porgress1.splice(0, 1, 100)
          break
        case 1:
          if (this.fileList2.length === 0) {
            this.fileList2.push([])
          }
          this.fileList2.splice(0, this.fileList2[0] ? 1 : 0, {
            fileName: file.name,
            fileSize: (file.size / 1024 / 1024).toFixed(2)
          })
          if (this.porgress2.length === 0) {
            this.porgress2.push([])
          }
          this.porgress2.splice(0, 1, 100)
          break
      }
    },
    billChange(e) {
      this.form.containerNumber = e
    },
    supplierType(e) {
      this.getCommData(e)
    },
    deleteFile() {
      this.fileList2 = []
      this.porgress2 = []
      this.uploadFile = null
    },
    formatTime(data) {
      const dtime = new Date(data)
      const year = dtime.getFullYear()
      let month = dtime.getMonth() + 1
      if (month < 10) {
          month = '0' + month
      }
      let day = dtime.getDate()
      if (day < 10) {
          day = '0' + day
      }
      return year + '-' + month + '-' + day
    },
    download() {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href = `/backEnd/api/${config.supplyCode}/file/downLoadTemplate?guid=e42348ee17504430a00e45f4c6587d8f`
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    },
    // 验证提单号
    checkBill: debounce(function(value, callback) {
      const param = {
        blNo: value
      }
      this.loading2 = true
      existsMBL(param).then((res) => {
          if (res.success) {
            if (res.data.length > 1) {
              this.loading2 = false
              this.showTip = true
              this.tableData2.columns = res.data
            } else if (res.data.length === 1) {
              this.form.containerNumber = res.data[0].containerNumber
              this.form.mblId = res.data[0].mblId
            }
            callback()
          } else {
            callback(new Error('提单号不存在，请重新输入'))
          }
        })
    }, 200),
    handlerRadio(v) {
      this.radioObj = v
    },
    confirm() {
      this.tableData2.columns = []
      this.form.blNo = this.radioObj.blNo
      this.form.mblId = this.radioObj.mblId
      this.form.containerNumber = this.radioObj.containerNumber
      this.showTip = false
    },
    visibilityChange(event) {
      const ev = event.target
      const evWeight = ev.scrollWidth // 文本的实际宽度
      const contentWeight = this.$refs.tlp.$el.parentNode.clientWidth // 文本容器宽度(父节点)
      if (evWeight > contentWeight) {
        // 文本宽度 > 实际内容宽度  -->内容溢出,则显示悬浮
        this.tooltipFlag = true
      } else {
        // 否则为未溢出，不显示悬浮
        this.tooltipFlag = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: left;
  .header {
    display: flex;
    margin-bottom: 20px;
  }
  .data {
    border-radius: 4px;
    overflow: hidden;
    .customTotal {
      width: 986px;
      height: 40px;
      background: #F7F7F7;
      border-radius: 0px 0px 4px 4px;
      border: 1px solid #EEEEEE;
      border-top: 0;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      box-sizing: border-box;
      span {
        font-weight: bold;
        color: #FE453D;
      }
    }
  }
  .form-item {
    position: relative;
    .lable {
      display: block;
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
    & + .form-item {
      margin-left: 20px;
    }
    .suffix {
      color: #666666;
      margin-right: 5px;
    }
    &:deep(.el-input__suffix) {
      display: flex;
      align-items: center;
    }
  }
  .other {
    flex: 1;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    align-self: flex-end;
    .tip {
      color: #FE453D;
    }
    .download {
      width: 145px;
      height: 30px;
      border-radius: 2px 2px 2px 2px;
      border: 1px solid #35BA83;
      color: #35BA83;
      margin-top: 5px;
      cursor: pointer;
    }
  }
  .upload {
    width: 140px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #2395f3;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    box-sizing: border-box;
  }
  .el-form {
    .el-col:nth-child(2n) {
      display: flex;
      justify-content: flex-end;
    }
    .label {
      span {
        font-size: 12px;
        color: #FE453D;
      }
    }
  }
  .fileList {
    width: auto;
    height: 40px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    align-self: flex-end;
  }
  .fileList2 {
    width: 320px;
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.1);
    border-radius: 6px 6px 6px 6px;
    margin-top: 10px;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .file {
    margin-left: 10px;
  }
  .fileName {
    width: 112px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .fileName2 {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }
  .fileSize {
    height: 16px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
  .fileSize2 {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
  }
  .upload-demo {
    width: 320px;
    height: 100px;
    background: #F7F7F7;
    border-radius: 4px 4px 4px 4px;
  }
  .icon {
    font-size: 20px;
    color: #CDCDCD;
    cursor: pointer;
  }
}
.calc {
  width: calc(100% - 80px);
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
<style lang="less">
.tooltip {
  width: 560px;
  height: auto;
  background: #FFFFFF !important;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.16);
  border-radius: 6px 6px 6px 6px;
  box-sizing: border-box;
  border: 0;
  // top: 147px !important;
}
.tooltip.is-dark[x-placement^="top-end"] .popper__arrow {
  border-top-color: transparent !important;
}
.tooltip[x-placement^=top-end] .popper__arrow{
  border-top-color: #FFFFFF;
}
.tooltip[x-placement^=top-end] .popper__arrow::before {
  position: absolute;
  top: 0px;
  content: '';
  display: block;
  width: 0;
  height: 0;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.16);
}
.tooltip[x-placement^=top-end] .popper__arrow::after {
  border-top-color: #FFFFFF;
}
.tooltip .data {
  width: calc(100% - 20px);
  margin: 0 auto;
}
.tooltip .footer {
  width: 100%;
  height: auto;
  margin-top: 20px;
  & div {
    width: 80px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    cursor: pointer;
  }
  .cancel {
    background: #EEEEEE;
    color: #999999;
    margin-right: 20px;
  }
  .confirm {
    background: #2395F3;
  }
}
</style>
