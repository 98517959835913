var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: _vm.dialogType ? "新增账单-一般" : "新增账单-分摊",
        visible: _vm.dialogVisible,
        width: _vm.dialogType ? "800px" : "1026px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
        top: "5%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { class: ["content", _vm.dialogType ? "calc" : ""] },
        [
          !_vm.dialogType
            ? [
                _c("div", { staticClass: "header" }, [
                  _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("导入分摊明细文件"),
                      ]),
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: "#",
                            "show-file-list": false,
                            "file-list": _vm.fileList1,
                            "http-request": _vm.httpRequest,
                          },
                        },
                        [
                          _c("div", { staticClass: "upload flex_c_c" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/bill/upload-1.png"),
                              },
                            }),
                            _vm._v(" 选择文件上传 "),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("账单日期"),
                      ]),
                      _c("el-date-picker", {
                        staticStyle: { width: "320px" },
                        attrs: { type: "date", placeholder: "请选择日期" },
                        model: {
                          value: _vm.invoiceDate,
                          callback: function ($$v) {
                            _vm.invoiceDate = $$v
                          },
                          expression: "invoiceDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("上传账单文件"),
                      ]),
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: "#",
                            "show-file-list": false,
                            "file-list": _vm.fileList2,
                            "http-request": (e) => _vm.httpRequest2(e, 0),
                          },
                        },
                        [
                          _c("div", { staticClass: "upload flex_c_c" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/bill/upload-1.png"),
                              },
                            }),
                            _vm._v(" 选择文件上传 "),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "fileList",
                      style: {
                        visibility:
                          _vm.fileList1[0] && _vm.fileList1[0].fileName
                            ? "visible"
                            : "hidden",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "1",
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex_c_c" },
                            [
                              _c("el-progress", {
                                attrs: {
                                  type: "circle",
                                  width: 40,
                                  height: 40,
                                  "stroke-width": 4,
                                  percentage: _vm.porgress1[0],
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "file" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  ref: "tlp",
                                  attrs: {
                                    content: _vm.fileList1[0]?.fileName,
                                    effect: "dark",
                                    disabled: !_vm.tooltipFlag,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "fileName",
                                      on: {
                                        mouseenter: function ($event) {
                                          return _vm.visibilityChange($event)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.fileList1[0]?.fileName))]
                                  ),
                                ]
                              ),
                              _c("p", { staticClass: "fileSize" }, [
                                _vm._v(
                                  _vm._s(_vm.fileList1[0]?.fileSize) + " mb"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "other" }, [
                    _c("div", { staticClass: "tip" }, [
                      _c("i", { staticClass: "el-icon-warning-outline" }),
                      _c("span", [_vm._v("分摊不支持账单识别")]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "download flex_c_c",
                        on: { click: _vm.download },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/order/download.png"),
                          },
                        }),
                        _vm._v(" 下载分摊明细模版 "),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "data" },
                  [
                    _c(
                      "BaseTable",
                      {
                        attrs: {
                          height: 280,
                          tableData: _vm.tableData,
                          loading: _vm.loading,
                          isPager: false,
                          border: true,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "customTotal flex_c_c",
                            attrs: { slot: "custom-total" },
                            slot: "custom-total",
                          },
                          [
                            _vm._v(" 总金额："),
                            _c("span", [_vm._v(_vm._s(_vm.total) + " USD")]),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-position": "top",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "账单号码",
                                  prop: "invoiceNumber",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "320px" },
                                  attrs: { placeholder: "请输入账单号码" },
                                  model: {
                                    value: _vm.form.invoiceNumber,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "invoiceNumber", $$v)
                                    },
                                    expression: "form.invoiceNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "供应商类型",
                                  prop: "supplierTypeId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择供应商类型",
                                    },
                                    model: {
                                      value: _vm.form.supplierTypeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "supplierTypeId",
                                          $$v
                                        )
                                      },
                                      expression: "form.supplierTypeId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.supplierTypeOptions,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.supplierType(
                                              item.dbValue
                                            )
                                          },
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "供应商", prop: "supplierId" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: {
                                      placeholder: "请选择供应商",
                                      filterable: "",
                                    },
                                    model: {
                                      value: _vm.form.supplierId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "supplierId", $$v)
                                      },
                                      expression: "form.supplierId",
                                    },
                                  },
                                  _vm._l(_vm.supplierOptions, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "账单金额",
                                  prop: "invoiceAmount",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "320px" },
                                  attrs: {
                                    oninput:
                                      "value=value.replace(/[^0-9.]/g,'')",
                                    placeholder: "请输入金额",
                                  },
                                  model: {
                                    value: _vm.form.invoiceAmount,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "invoiceAmount", $$v)
                                    },
                                    expression: "form.invoiceAmount",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "币制", prop: "currency" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.form.currency,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "currency", $$v)
                                      },
                                      expression: "form.currency",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "USD", value: "USD" },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "RMB", value: "RMB" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "柜号",
                                  prop: "containerNumber",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "320px" },
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.form.containerNumber,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "containerNumber", $$v)
                                    },
                                    expression: "form.containerNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "blNo" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "label",
                                    attrs: { slot: "label" },
                                    slot: "label",
                                  },
                                  [
                                    _vm._v(" 提单号"),
                                    _c("span", [
                                      _vm._v(
                                        "(输入提单/柜号；如有多条分单必须选中一条)"
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      "popper-class": "tooltip",
                                      manual: true,
                                      effect: "dark",
                                      placement: "top-end",
                                    },
                                    model: {
                                      value: _vm.showTip,
                                      callback: function ($$v) {
                                        _vm.showTip = $$v
                                      },
                                      expression: "showTip",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "320px" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请输入提单号",
                                      },
                                      model: {
                                        value: _vm.form.blNo,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "blNo", $$v)
                                        },
                                        expression: "form.blNo",
                                      },
                                    }),
                                    _c("template", { slot: "content" }, [
                                      _c(
                                        "div",
                                        { staticClass: "data" },
                                        [
                                          _c("BaseTable", {
                                            attrs: {
                                              height: "240",
                                              tableData: _vm.tableData2,
                                              loading: _vm.loading2,
                                              isPager: false,
                                            },
                                            on: {
                                              handlerRadio: _vm.handlerRadio,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "footer flex_c_c" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "cancel flex_c_c",
                                              on: {
                                                click: function ($event) {
                                                  _vm.showTip = false
                                                },
                                              },
                                            },
                                            [_vm._v("取消")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "confirm flex_c_c",
                                              on: { click: _vm.confirm },
                                            },
                                            [_vm._v("确定")]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c(
                            "div",
                            { staticClass: "form-item" },
                            [
                              _c("label", { staticClass: "lable" }, [
                                _c("span", { staticClass: "red" }, [
                                  _vm._v("* "),
                                ]),
                                _vm._v("上传账单文件"),
                              ]),
                              _c(
                                "el-upload",
                                {
                                  staticClass: "upload-demo flex_c_c",
                                  attrs: {
                                    action: "#",
                                    "show-file-list": false,
                                    "file-list": _vm.fileList2,
                                    "http-request": (e) =>
                                      _vm.httpRequest2(e, 1),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "upload flex_c_c" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/bill/upload-1.png"),
                                        },
                                      }),
                                      _vm._v(" 选择文件上传 "),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "fileList2 flex_c_c",
                                  style: {
                                    visibility:
                                      _vm.fileList2[0] &&
                                      _vm.fileList2[0].fileName
                                        ? "visible"
                                        : "hidden",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        flex: "1",
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex_c_c" },
                                        [
                                          _c("el-progress", {
                                            attrs: {
                                              type: "circle",
                                              width: 40,
                                              height: 40,
                                              "stroke-width": 4,
                                              percentage: _vm.porgress2[0],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "file" }, [
                                        _c("p", { staticClass: "fileName2" }, [
                                          _vm._v(
                                            _vm._s(_vm.fileList2[0]?.fileName)
                                          ),
                                        ]),
                                        _c("p", { staticClass: "fileSize2" }, [
                                          _vm._v(
                                            _vm._s(_vm.fileList2[0]?.fileSize) +
                                              " mb"
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c("div", { staticClass: "flex_c_c" }, [
                                    _c("i", {
                                      staticClass: "el-icon-delete icon",
                                      attrs: { title: "删除" },
                                      on: { click: _vm.deleteFile },
                                    }),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
          _c("div", { staticClass: "ok flex_c_c", on: { click: _vm.ok } }, [
            _vm._v("确定新增"),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }