var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "tab" },
          _vm._l(_vm.tabList, function (item, $index) {
            return _c(
              "span",
              {
                directives: [
                  {
                    name: "permissson",
                    rawName: "v-permissson",
                    value: `${item.permissson}`,
                    expression: "`${item.permissson}`",
                  },
                ],
                key: item.permissson,
                class: ["tabItem", _vm.active === $index ? "active" : ""],
                on: {
                  click: function ($event) {
                    return _vm.changeType($index)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(item.name) +
                    " (" +
                    _vm._s(
                      item.type === 10000
                        ? _vm.tabTotal
                        : _vm.tabNumer.find((a) => a.type === item.type)?.qty ||
                            0
                    ) +
                    ") "
                ),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "opt" },
          [
            _vm._l(_vm.recognitionList, function (item, $index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === $index,
                      expression: "active === $index",
                    },
                    {
                      name: "permissson",
                      rawName: "v-permissson",
                      value: `${item}`,
                      expression: "`${item}`",
                    },
                  ],
                  key: item,
                  staticClass: "recognition flex_c_c",
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible2 = true
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/const/recognition.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" 供应商账单自动识别 "),
                ]
              )
            }),
            _vm._l(_vm.deriveListA, function (item, $index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === $index,
                      expression: "active === $index",
                    },
                    {
                      name: "permissson",
                      rawName: "v-permissson",
                      value: `${item}`,
                      expression: "`${item}`",
                    },
                  ],
                  key: item,
                  staticClass: "derive_a flex_c_c",
                  on: { click: _vm.derive },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/bill/import.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" 导出 "),
                ]
              )
            }),
            _vm._l(_vm.addNormalList, function (item, $index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === $index,
                      expression: "active === $index",
                    },
                    {
                      name: "permissson",
                      rawName: "v-permissson",
                      value: `${item}`,
                      expression: "`${item}`",
                    },
                  ],
                  key: item,
                  staticClass: "add flex_c_c",
                  on: {
                    click: function ($event) {
                      ;(_vm.dialogVisible3 = true), (_vm.dialogType = 1)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/const/add.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" 新增一般账单 "),
                ]
              )
            }),
            _vm._l(_vm.addList, function (item, $index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === $index,
                      expression: "active === $index",
                    },
                    {
                      name: "permissson",
                      rawName: "v-permissson",
                      value: `${item}`,
                      expression: "`${item}`",
                    },
                  ],
                  key: item,
                  staticClass: "add flex_c_c",
                  on: {
                    click: function ($event) {
                      ;(_vm.dialogVisible3 = true), (_vm.dialogType = 0)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/const/add.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" 新增分摊账单 "),
                ]
              )
            }),
            _c(
              "BaseFilter",
              {
                attrs: { isActive: _vm.isActive, width: 494 },
                on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
              },
              [
                _c(
                  "div",
                  { staticClass: "filter" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.filterList,
                          "label-width": "104px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "创建时间" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "320px" },
                              attrs: {
                                type: "daterange",
                                "range-separator": "至",
                                align: "center",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                "unlink-panels": "",
                                "picker-options": _vm.pickerOptions,
                              },
                              model: {
                                value: _vm.filterList.createDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "createDate", $$v)
                                },
                                expression: "filterList.createDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "业务员" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "320px" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                  multiple: "",
                                },
                                model: {
                                  value: _vm.filterList.saleIds,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterList, "saleIds", $$v)
                                  },
                                  expression: "filterList.saleIds",
                                },
                              },
                              _vm._l(_vm.userOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "供应商" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "320px" },
                                attrs: { placeholder: "请选择供应商" },
                                model: {
                                  value: _vm.filterList.supplierId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterList, "supplierId", $$v)
                                  },
                                  expression: "filterList.supplierId",
                                },
                              },
                              _vm._l(_vm.supplierOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "申付状态" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "320px" },
                                attrs: { placeholder: "请选择供应商" },
                                model: {
                                  value: _vm.filterList.defrayStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filterList,
                                      "defrayStatus",
                                      $$v
                                    )
                                  },
                                  expression: "filterList.defrayStatus",
                                },
                              },
                              _vm._l(_vm.defrayStatusOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { "line-height": "20px" },
                                attrs: { slot: "label" },
                                slot: "label",
                              },
                              [
                                _vm._v(" 提单号/账单号"),
                                _c("br"),
                                _vm._v("/柜号 "),
                              ]
                            ),
                            _c("base-muti-input", {
                              ref: "baseMutiInput",
                              staticStyle: { width: "320px" },
                              model: {
                                value: _vm.filterList.numbers,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "numbers", $$v)
                                },
                                expression: "filterList.numbers",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                key: _vm.active,
                attrs: {
                  height: _vm.height,
                  tableData: {
                    ..._vm.tableData,
                    fileds: _vm.fileds,
                  },
                  pageIndex: _vm.pager.pageIndex,
                  pageSize: _vm.pager.pageSize,
                  loading: _vm.loading,
                },
                on: {
                  handlePageChange: _vm.handlePageChange,
                  handleSelectionChange: _vm.handleSelectionChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "statusName",
                      fn: function (row) {
                        return [
                          row.scope.status === 0
                            ? _c("div", { staticClass: "status status1" }, [
                                _vm._v(
                                  " " + _vm._s(row.scope.statusName) + " "
                                ),
                              ])
                            : row.scope.status === 5
                            ? _c("div", { staticClass: "status status2" }, [
                                _vm._v(
                                  " " + _vm._s(row.scope.statusName) + " "
                                ),
                              ])
                            : _c("div", { staticClass: "status status3" }, [
                                _vm._v(_vm._s(row.scope.statusName)),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "defrayStatusName",
                      fn: function (row) {
                        return [
                          row.scope.defrayStatus === 0
                            ? _c("div", { staticClass: "status status1" }, [
                                _vm._v(
                                  " " + _vm._s(row.scope.defrayStatusName) + " "
                                ),
                              ])
                            : row.scope.defrayStatus === 1
                            ? _c("div", { staticClass: "status status2" }, [
                                _vm._v(
                                  " " + _vm._s(row.scope.defrayStatusName) + " "
                                ),
                              ])
                            : _c("div", { staticClass: "status status3" }, [
                                _vm._v(_vm._s(row.scope.defrayStatusName)),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c("div", { staticClass: "table-opt" }, [
                            _vm.active === 2
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "audit",
                                        expression: "'audit'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.audit(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("审核")]
                                )
                              : _vm._e(),
                            _vm.active === 3
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "counteraudit",
                                        expression: "'counteraudit'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.counteraudit(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("反审核")]
                                )
                              : _vm._e(),
                            _vm.active === 0
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_a",
                                        expression: "'detail_a'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.detail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                )
                              : _vm._e(),
                            _vm.active === 1
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_b",
                                        expression: "'detail_b'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.detail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                )
                              : _vm._e(),
                            _vm.active === 2
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_c",
                                        expression: "'detail_c'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.detail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                )
                              : _vm._e(),
                            _vm.active === 3
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_d",
                                        expression: "'detail_d'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.detail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                )
                              : _vm._e(),
                            _vm.active === 1
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "delete_b",
                                        expression: "'delete_b'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRow(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                            _vm.active === 2
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "delete_c",
                                        expression: "'delete_c'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRow(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                            _vm.active === 4
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_e",
                                        expression: "'detail_e'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.detail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "custom-total",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "custom-total" }, [
                            _c("div", { staticStyle: { display: "flex" } }, [
                              _c("div", { staticClass: "select" }, [
                                _vm._v(
                                  "已选 " +
                                    _vm._s(_vm.selectionItem.length) +
                                    " 项"
                                ),
                              ]),
                              _vm.selectionItem.length
                                ? _c("div", {
                                    staticClass: "total",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.totalHtml),
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "total" }, [
                              _vm._v(" 应付合计："),
                              _c("span", [
                                _vm._v(_vm._s(_vm.totalAmount) + " USD"),
                              ]),
                            ]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  4194992841
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("AddBillDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible3,
          dialogType: _vm.dialogType,
        },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible3 = false
          },
          ok: _vm.ok,
        },
      }),
      _c("CopeWithDetailDialog", {
        attrs: { dialogVisible: _vm.dialogVisible4, rowData: _vm.rowData },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible4 = false
          },
          ok: _vm.ok2,
        },
      }),
      _c("SupplierRecognitionDialog", {
        attrs: { dialogVisible: _vm.dialogVisible2 },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible2 = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }