<template>
  <div class="view-contanier">
    <div class="header">
      <div class="tab">
        <span
          v-for="(item, $index) in tabList"
          :key="item.permissson"
          v-permissson="`${item.permissson}`"
          :class="['tabItem', active === $index ? 'active' : '']"
          @click="changeType($index)"
        >
          {{ item.name }}
          ({{ item.type === 10000 ? tabTotal : tabNumer.find(a => a.type === item.type)?.qty || 0 }})
        </span>
      </div>
      <div class="opt">
        <div
          v-for="(item, $index) in recognitionList"
          v-show="active === $index"
          :key="item"
          v-permissson="`${item}`"
          class="recognition flex_c_c"
          @click="dialogVisible2 = true"
        >
          <img src="@/assets/images/const/recognition.png" alt="" />
          供应商账单自动识别
        </div>
        <div
          v-for="(item, $index) in deriveListA"
          v-show="active === $index"
          :key="item"
          v-permissson="`${item}`"
          class="derive_a flex_c_c"
          @click="derive"
        >
          <img src="@/assets/images/bill/import.png" alt="" />
          导出
        </div>
        <div
          v-for="(item, $index) in addNormalList"
          v-show="active === $index"
          :key="item"
          v-permissson="`${item}`"
          class="add flex_c_c"
          @click="(dialogVisible3 = true), (dialogType = 1)"
        >
          <img src="@/assets/images/const/add.png" alt="" />
          新增一般账单
        </div>

        <div
          v-for="(item, $index) in addList"
          v-show="active === $index"
          :key="item"
          v-permissson="`${item}`"
          class="add flex_c_c"
          @click="(dialogVisible3 = true), (dialogType = 0)"
        >
          <img src="@/assets/images/const/add.png" alt="" />
          新增分摊账单
        </div>
        <BaseFilter
          :isActive="isActive"
          :width="494"
          @sendFilter="sendFilter"
          @reset="reset"
        >
          <div class="filter">
            <el-form ref="form" :model="filterList" label-width="104px">
              <el-form-item label="创建时间">
                <el-date-picker
                  v-model="filterList.createDate"
                  style="width: 320px"
                  type="daterange"
                  range-separator="至"
                  align="center"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  unlink-panels
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
              <!-- <el-form-item label="账单日期">
                <el-date-picker
                  v-model="filterList.invoiceDate"
                  style="width: 100%"
                  type="daterange"
                  range-separator="至"
                  align="center"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item> -->
              <el-form-item label="业务员">
                <el-select
                  v-model="filterList.saleIds"
                  style="width: 320px"
                  filterable
                  placeholder="请选择"
                  multiple
                >
                  <el-option
                    v-for="item in userOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="供应商">
                <el-select
                  v-model="filterList.supplierId"
                  style="width: 320px"
                  placeholder="请选择供应商"
                >
                  <el-option
                    v-for="item in supplierOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="申付状态">
                <el-select
                  v-model="filterList.defrayStatus"
                  style="width: 320px"
                  placeholder="请选择供应商"
                >
                  <el-option
                    v-for="item in defrayStatusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <div slot="label" style="line-height: 20px">
                  提单号/账单号<br />/柜号
                </div>
                <base-muti-input
                  ref="baseMutiInput"
                  v-model="filterList.numbers"
                  style="width: 320px"
                ></base-muti-input>
              </el-form-item>
            </el-form>
          </div>
        </BaseFilter>
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :key="active"
        :height="height"
        :tableData="{
          ...tableData,
          fileds,
        }"
        :pageIndex="pager.pageIndex"
        :pageSize="pager.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange"
        @handleSelectionChange="handleSelectionChange"
      >
        <template slot="statusName" slot-scope="row">
          <div v-if="row.scope.status === 0" class="status status1">
            {{ row.scope.statusName }}
          </div>
          <div v-else-if="row.scope.status === 5" class="status status2">
            {{ row.scope.statusName }}
          </div>
          <div v-else class="status status3">{{ row.scope.statusName }}</div>
        </template>
        <template slot="defrayStatusName" slot-scope="row">
          <div v-if="row.scope.defrayStatus === 0" class="status status1">
            {{ row.scope.defrayStatusName }}
          </div>
          <div v-else-if="row.scope.defrayStatus === 1" class="status status2">
            {{ row.scope.defrayStatusName }}
          </div>
          <div v-else class="status status3">{{ row.scope.defrayStatusName }}</div>
        </template>
        <template slot="opt" slot-scope="row">
          <div class="table-opt">
            <span
              v-if="active === 2"
              v-permissson="'audit'"
              @click="audit(row.scope)"
            >审核</span
            >
            <span
              v-if="active === 3"
              v-permissson="'counteraudit'"
              @click="counteraudit(row.scope)"
            >反审核</span
            >
            <span
              v-if="active === 0"
              v-permissson="'detail_a'"
              @click="detail(row.scope)"
            >详情</span
            >
            <span
              v-if="active === 1"
              v-permissson="'detail_b'"
              @click="detail(row.scope)"
            >详情</span
            >
            <span
              v-if="active === 2"
              v-permissson="'detail_c'"
              @click="detail(row.scope)"
            >详情</span
            >
            <span
              v-if="active === 3"
              v-permissson="'detail_d'"
              @click="detail(row.scope)"
            >详情</span
            >
            <span
              v-if="active === 1"
              v-permissson="'delete_b'"
              @click="deleteRow(row.scope)"
            >删除</span
            >
            <span
              v-if="active === 2"
              v-permissson="'delete_c'"
              @click="deleteRow(row.scope)"
            >删除</span
            >
            <span
              v-if="active === 4"
              v-permissson="'detail_e'"
              @click="detail(row.scope)"
            >详情</span
            >
          </div>
        </template>
        <template #custom-total>
          <div class="custom-total">
            <div style="display: flex;">
              <div class="select">已选 {{ selectionItem.length }} 项</div>
              <div v-if="selectionItem.length" class="total" v-html="totalHtml">
              </div>
            </div>
            <div class="total">
              应付合计：<span>{{ totalAmount }} USD</span>
            </div>
          </div>
        </template>
      </BaseTable>
    </div>
    <!-- <BillRecognitionDialog
      :dialogVisible="dialogVisible2"
      @cancel="dialogVisible2 = false"
    ></BillRecognitionDialog> -->
    <AddBillDialog
      :dialogVisible="dialogVisible3"
      :dialogType="dialogType"
      @cancel="dialogVisible3 = false"
      @ok="ok"
    ></AddBillDialog>
    <CopeWithDetailDialog
      :dialogVisible="dialogVisible4"
      :rowData="rowData"
      @cancel="dialogVisible4 = false"
      @ok="ok2"
    ></CopeWithDetailDialog>
    <SupplierRecognitionDialog
      :dialogVisible="dialogVisible2"
      @cancel="dialogVisible2 = false"
    ></SupplierRecognitionDialog>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseFilter = () => import('@/components/filter/BaseFilter')
const BaseMutiInput = () => import('@/components/base/BaseMutiInput.vue')
// import BillRecognitionDialog from './components/BillRecognitionDialog'
import AddBillDialog from './components/AddBillDialog'
import CopeWithDetailDialog from './components/CopeWithDetailDialog'
import SupplierRecognitionDialog from './components/SupplierRecognitionDialog'
import { permissions, derive } from '@/utils/common'
import { getSupplierDropDownList, getSaleList } from '@/api/system'
import {
  getPayablePages,
  reviewedPayableInvoice,
  deletePayableInvoice,
  repeatReviewedPayableInvoice
} from '@/api/fee'
import { myMixins } from '@/mixins'
export default {
  name: 'CopeWith',
  components: {
    BaseTable,
    BaseFilter,
    BaseMutiInput,
    // BillRecognitionDialog,
    AddBillDialog,
    CopeWithDetailDialog,
    SupplierRecognitionDialog
  },
  mixins: [myMixins],
  data() {
    return {
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
      active: 0,
      tableData: {
        fileds: [
          // {
          //   filed: 'invoiceDate',
          //   label: '账单日期',
          //   width: '120',
          //   formatter: (row) =>
          //     row.invoiceDate
          //       ? row.invoiceDate.slice(0, row.invoiceDate.length - 9)
          //       : ''
          // },
          {
            filed: 'invoiceNumber',
            label: '账单号码',
            width: '180',
            isCopy: true
          },
          {
            filed: 'blNo',
            label: '主提单号',
            width: '160',
            isCopy: true,
            align: 'left'
          },
          {
            filed: 'containerNumber',
            label: '柜号',
            width: '140',
            isCopy: true
          },
          {
            filed: 'mblTypeName',
            label: '提单类型',
            width: '120'
          },
          {
            filed: 'supplierTypeName',
            label: '供应商类型',
            width: '140'
          },
          {
            filed: 'pickUpTypeName',
            label: '提货方式',
            width: '140'
          },
          {
            filed: 'supplieName',
            label: '供应商',
            width: '140'
          },
          {
            filed: 'companyName',
            label: '客户',
            width: '210'
          },
          {
            filed: 'currency',
            label: '币制',
            width: '100'
          },
          {
            filed: 'invoiceAmount',
            label: '账单金额',
            width: '150',
            formatter: (row) => {
              return row.invoiceAmount ? row.invoiceAmount + ` ${row.currency}` : `0 ${row.currency}`
            }
          },
          {
            filed: 'createdName',
            label: '录入人',
            width: '100'
          },
          // {
          //   filed: 'defrayStatusName',
          //   label: '申付状态',
          //   width: '120',
          //  isCustom: true
          // },
          {
            filed: 'eta',
            label: 'ETA',
            width: '120',
            formatter: (row) => {
              return row.eta ? row.eta.slice(0, row.eta.length - 9) : ''
            }
          },
          {
            filed: 'createdOn',
            label: '申付日期',
            width: '160',
            formatter: (row) => {
              return row.createdOn
                ? row.createdOn.slice(0, row.createdOn.length - 3)
                : ''
            }
          },
          {
            filed: 'saleName',
            label: '销售员',
            width: '180'
          }
        ],
        columns: [],
        total: 0,
        mutiCheck: true,
        mutiFixed: 'left'
      },
      loading: false,
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      height: 0,
      filterList: {
        numbers: '',
        createDate: '',
        // invoiceDate: '',
        supplierId: '',
        defrayStatus: 'All',
        saleIds: ''
      },
      isActive: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogType: 0,
      rowData: {},
      selectionItem: [],
      supplierOptions: [],
      userOptions: [],
      tabNumer: [],
      totalAmount: 0,
      tabTotal: 0,
      flag: false,
      tabList: [
        { name: '全部', permissson: 'QB', type: 10000 },
        { name: '未录入', permissson: 'WLR', type: 0 },
        { name: '未审核', permissson: 'WSH', type: 5 },
        { name: '已审核', permissson: 'YSH', type: 10 },
        { name: '已申付', permissson: 'YSF', type: 15 }
      ], // tab按钮权限
      defrayStatusOptions: [{
        value: 'All',
        label: '全部'
      }, {
        value: 0,
        label: '未申付'
      }, {
        value: 1,
        label: '申付完成'
      }],
      recognitionList: [
        'recognition_a',
        'recognition_b',
        'recognition_c',
        'recognition_d',
        'recognition_e'
      ], // 识别按钮的权限验证
      addNormalList: ['addNormal_a', 'addNormal_b', 'addNormal_c', 'addNormal_d', 'addNormal_e'], // 新增一般账单
      deriveListA: ['derive_a', 'derive_b', 'derive_c', 'derive_d', 'derive_e'], // 导出按钮的权限验证
      addList: ['addShare_a', 'addShare_b', 'addShare_c', 'addShare_d', 'addShare_e'] // 新增分摊账单
    }
  },
  computed: {
    fileds() {
      const arr = [
        {
          filed: 'statusName',
          label: '状态',
          width: '90',
          isCustom: true
        }
      ]
      const opt = {
        filed: 'opt',
        label: '操作',
        width: '150',
        fixed: 'right',
        isCustom: true
      }
      return this.active
        ? [...this.tableData.fileds, opt]
        : [...arr, ...this.tableData.fileds, opt]
    },
    totalHtml() {
      const arr = []
      this.selectionItem.forEach(a => {
        const item = arr.find(n => n.currency === a.currency)
        if (!item) {
          arr.push({
            currency: a.currency,
            num: a.invoiceAmount
          })
        } else {
          item.num += +a.invoiceAmount
        }
      })
      let str = ' 账单金额：'
      arr.forEach((a, index) => {
        str += `<span style="color: #fe453d;font-weight: bold;${index > 0 ? 'margin-left: 10px' : ''}"> ${a.num}  ${a.currency}</span>`
      })
      return str
    }
  },
  created() {
    this.getUserList()
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 93
    if (!this.$store.state.connect.connect.tabCode) {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const codeList = ['QB', 'WLR', 'WSH', 'YSH']
      const childrenCode = menu.children.map(a => a.code)
      this.active = codeList.findIndex((a) => childrenCode.includes(a))
      this.getList(this.pager)
      this.getCommData()
    }
  },
  methods: {
    getUserList() {
      getSaleList().then((res) => {
        if (res.success) {
          const arr = []
          res.data.map((item) => {
            arr.push({
              value: item.id,
              label: item.nameCH
            })
          })
          this.userOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    getCommData() {
      getSupplierDropDownList({}).then((res) => {
        if (res.success) {
          res.data &&
            (this.supplierOptions = res.data.map((a) => {
              return {
                label: `${a.code}-${a.name}`,
                value: a.id
              }
            }))
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    changeType(type) {
      this.active = type
      const param = {
        pageIndex: 1,
        pageSize: this.pager.pageSize
      }
      this.pager = param
      this.selectionItem = []
      this.getList(param)
    },
    async getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.formatterFilter()
          }
        }
        const res = await getPayablePages(params)
        if (res.success) {
          this.loading = false
          this.tableData.columns = res.data.list
          this.tableData.total = res.data.total
          this.tabNumer = res.data.tabNumer
          this.tabTotal = res.data.tabTotal
          this.flag && (this.detail(this.tableData.columns[0]), this.flag = false)
          res.data.totalAmount && (this.totalAmount = res.data.totalAmount)
        } else {
          this.loading = false
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    formatterFilter() {
      const statusArr = [undefined, 0, 5, 10, 15]
      return {
        status: statusArr.find((a, i) => i === this.active),
        numbers:
          this.filterList.numbers.length > 0
            ? this.filterList.numbers
            : undefined,
        saleIds:
          this.filterList.saleIds.length > 0
            ? this.filterList.saleIds
            : undefined,
        supplierId:
          this.filterList.date !== ''
            ? this.filterList.supplierId
            : undefined,
        createBeginDate:
          this.filterList.date !== ''
            ? this.filterList.createDate[0]
            : undefined,
        createEndDate:
          this.filterList.date !== ''
            ? this.filterList.createDate[1]
            : undefined,
        defrayStatus:
          this.filterList.defrayStatus !== 'All'
            ? this.filterList.defrayStatus
            : undefined
      }
    },
    initList() {
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = param
      this.getList(param)
    },
    sendFilter() {
      if (
        this.filterList.createDate !== '' ||
        this.filterList.supplierId !== '' ||
        this.filterList.numbers.length !== 0 ||
        this.filterList.saleIds.length !== 0 ||
        this.filterList.invoiceDate !== '' ||
        this.filterList.defrayStatus !== 'All'
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = params
      this.getList(params)
    },
    reset() {
      this.filterList = {
        numbers: '',
        createDate: '',
        supplierId: '',
        invoiceDate: '',
        saleIds: '',
        defrayStatus: 'All'
      }
      this.$refs.baseMutiInput.reset()
      this.isActive = false
    },
    handlePageChange(page) {
      this.pager = page
      this.getList(page)
    },
    handleSelectionChange(items) {
      this.selectionItem = items
    },
    // 详情
    detail(row) {
      this.rowData = row
      this.dialogVisible4 = true
    },
    // 审核
    async audit(row) {
      try {
        const result = await reviewedPayableInvoice({ id: row.id })
        if (result.success) {
          this.getList(this.pager)
          this.$message.success(result.errorMessage)
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    // 反审核
    counteraudit(row) {
      this.$confirm('是否确定要进行反审核？', '反审核提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        iconClass: 'warning',
        showClose: false,
        closeOnPressEscape: false,
        closeOnClickModal: false,
        center: true
      })
        .then(async() => {
          try {
            const result = await repeatReviewedPayableInvoice({ id: row.id })
            if (result.success) {
              this.getList(this.pager)
              this.$message.success(result.errorMessage)
            } else {
              this.$message.error(result.errorMessage)
            }
          } catch (error) {
            console.error(error)
          }
        })
        .catch(() => {})
    },
    // 删除
    async deleteRow(row) {
      try {
        const result = await deletePayableInvoice({ id: row.id })
        if (result.success) {
          this.getList(this.pager)
          this.$message.success(result.errorMessage)
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    ok() {
      this.dialogVisible1 = false
      this.dialogVisible3 = false
      this.flag = true
      this.initList()
    },
    ok2() {
      this.getList(this.pager)
    },
    // 导出
    derive() {
      const params = {
        ...this.formatterFilter()
      }
      derive('Payable/exportPayable', 'post', params)
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tabItem {
      padding: 15px 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      cursor: pointer;
      box-sizing: border-box;
    }
    .active {
      color: #2395f3;
    }
  }
  .opt {
    display: flex;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    & div {
      height: 30px;
      box-sizing: border-box;
      margin-right: 5px;
      border-radius: 4px;
      cursor: pointer;
    }
    .recognition {
      width: 160px;
      border: 1px solid #35ba83;
      color: #35ba83;
    }
    .import {
      width: 80px;
      background: #35ba83;
      color: #ffffff;
    }
    .derive_a {
      width: 80px;
      background: #2395f3;
    }
    .add {
      width: 120px;
      background: #2395f3;
    }
    .derive_b {
      width: 120px;
      border: 1px solid #2395f3;
      color: #2395f3;
    }
    .status {
      width: 60px;
      height: 26px;
      border-radius: 4px 4px 4px 4px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
    }
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
    .status {
      display: inline-block;
      width: auto;
      padding: 0 12px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      border-radius: 4px 4px 4px 4px;
    }
    .status1 {
      color: #feaa4f;
      background: rgba(254, 170, 79, 0.1);
    }
    .status2 {
      color: #ff7444;
      background: rgba(255, 116, 68, 0.1);
    }
    .status3 {
      color: #35ba83;
      background: rgba(53, 186, 131, 0.1);
    }
  }

     .custom-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #f7f7f7;
    border: 1px solid #eeeeee;
    border-top: 0;
    padding-left: 17px;
    padding-right: 40px;
    box-sizing: border-box;
    .select {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
    .total {
      margin-left: 16px;
      font-size: 14px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      color: #333333;
      span {
        color: #fe453d;
        font-weight: bold;
      }
    }
  }
  .table-opt {
    display: flex;
    justify-content: space-around;
    span {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      cursor: pointer;
      & + span {
        margin-left: 20px;
      }
    }
  }
}
</style>
