<template>
  <el-dialog
    v-drag
    title="供应商自动账单识别"
    :visible.sync="dialogVisible"
    width="1340px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="header">
        <div class="form-item">
          <label class="lable"><span class="red">* </span>选择账单模板</label>
          <el-select
            v-model="templateVal"
            placeholder="请选择"
            style="width: 320px"
          >
            <el-option
              v-for="item in templateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="data">
        <BaseTable
          :height="400"
          :tableData="tableData"
          :loading="loading"
          :isPager="false"
          :border="true"
        >
          <template slot="identifyFeeName" slot-scope="row">
            <el-input
              v-if="row.scope.isEdit || row.scope.isAdd"
              v-model="row.scope.identifyFeeName"
              placeholder="请输入"
            ></el-input>
            <span v-else>{{ row.scope.identifyFeeName }}</span>
          </template>
          <template slot="feeNameId" slot-scope="row">
            <el-select
              v-if="row.scope.isEdit || row.scope.isAdd"
              v-model="row.scope.feeNameId"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in feeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span v-else>{{
              feeOptions.find((a) => a.value === row.scope.feeNameId)?.label
            }}</span>
          </template>
          <template slot="isShare" slot-scope="row">
            <el-switch
              v-model="row.scope.isShare"
              :active-text="row.scope.isShare ? '是' : '否'"
              :disabled="!row.scope.isEdit && !!row.scope.id"
            >
            </el-switch>
          </template>
          <template slot="isContains" slot-scope="row">
            <el-switch
              v-model="row.scope.isContains"
              :active-text="row.scope.isContains ? '是' : '否'"
              :disabled="!row.scope.isEdit && !!row.scope.id"
            >
            </el-switch>
          </template>
          <template slot="isActived" slot-scope="row">
            <el-switch
              v-model="row.scope.isActived"
              :active-text="row.scope.isActived ? '是' : '否'"
              :disabled="!row.scope.isEdit && !!row.scope.id"
            >
            </el-switch>
          </template>
          <template slot="opt" slot-scope="row">
            <div class="tab-opt">
              <template v-if="!row.scope.isEdit && !row.scope.isAdd">
                <img
                  src="@/assets/images/const/edit.png"
                  title="编辑"
                  @click="row.scope.isEdit = true"
                />
                <img src="@/assets/images/const/delete.png" title="删除" @click="deleteRow(row.scope)" />
              </template>
              <template v-if="row.scope.isEdit && !row.scope.isAdd">
                <div @click="modify(row.scope, 1)">确定修改</div>
              </template>
              <template v-if="!row.scope.isEdit && row.scope.isAdd">
                <div @click="modify(row.scope, 0)">添加</div>
              </template>
            </div>
          </template>
        </BaseTable>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div class="ok flex_c_c" @click="ok">确定</div>
    </span>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
import {
  getSupplierFeeIdentifyTemplate,
  getSupplierFeeIdentifyTemplateDetail,
  GetFeeNameList,
  modifySupplierFeeIdentify,
  delSupplierFeeIdentify
} from '@/api/fee'
export default {
  components: {
    BaseTable
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      templateVal: '',
      templateOptions: [],
      feeOptions: [],
      tableData: {
        fileds: [
          {
            filed: 'templateCode',
            label: '模板代码',
            width: '100',
            resizable: true
          },
          {
            filed: 'identifyFeeName',
            label: '识别字符',
            width: '140',
            resizable: true,
            isCustom: true
          },
          {
            filed: 'supplierName',
            label: '供应商',
            width: '180',
            resizable: true
          },
          {
            filed: 'feeNameId',
            label: '费用细类',
            width: '180',
            resizable: true,
            isCustom: true
          },
          {
            filed: 'isShare',
            label: '是否分摊',
            width: '100',
            resizable: true,
            isCustom: true
          },
          {
            filed: 'isContains',
            label: '是否模糊查找',
            width: '100',
            resizable: true,
            isCustom: true
          },
          {
            filed: 'isActived',
            label: '是否启用',
            width: '100',
            resizable: true,
            isCustom: true
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: '130',
            resizable: true,
            formatter: (row) => row.createdName || '--'
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '165',
            resizable: true,
            formatter: (row) =>
              row.createdOn
                ? row.createdOn.slice(0, row.createdOn.length - 3)
                : '--'
          },
          {
            filed: 'opt',
            label: '操作',
            width: '104',
            resizable: true,
            isCustom: true
          }
        ],
        columns: []
      },
      loading: false
    }
  },
  watch: {
    async dialogVisible(val) {
      if (val) {
        await this.getTemplateList()
        await this.getTemplateDetailList(true)
        this.GetFeeNameList()
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    ok() {
      this.$emit('cancel')
    },
    async getTemplateList() {
      try {
        const res = await getSupplierFeeIdentifyTemplate()
        if (res.success) {
          this.templateOptions = res.data.map((a) => {
            const label = `${a.templateCode}-${a.supplierName.split('-')[1]}`
            return {
              label: label,
              value: a.templateCode
            }
          })
          this.templateVal = this.templateOptions[0].value
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getTemplateDetailList(isAdd) {
      try {
        const res = await getSupplierFeeIdentifyTemplateDetail({
          templateCode: this.templateVal
        })
        if (res.success) {
          this.tableData.columns = res.data.map((a) => {
            return {
              ...a,
              isEdit: false,
              isAdd: false
            }
          })
          isAdd && this.tableData.columns.push({
            isShare: false,
            templateCode: res.data[res.data.length - 1].templateCode,
            supplierName: res.data[res.data.length - 1].supplierName,
            supplierId: res.data[res.data.length - 1].supplierId,
            identifyFeeName: '',
            feeNameId: '',
            isContains: false,
            isActived: false,
            isAdd: true
          })
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    // 费用项
    async GetFeeNameList() {
      try {
        const res = await GetFeeNameList({ feeTypeCode: 'MBLF' })
        const arr = res.data.map((item) => {
          return {
            label: `${item.code} - ${item.name}`,
            value: item.id
          }
        })
        this.feeOptions = arr
      } catch (error) {
        console.log(error)
      }
    },
    async modify(row, type) {
      const { identifyFeeName, supplierId } = row
      if (!identifyFeeName || !supplierId) {
        this.$message.error('请填写完整识别字符和选择费用细类')
        return
      }
      try {
        const res = await modifySupplierFeeIdentify(row)
        if (res.success) {
          this.$message.success(res.errorMessage)
          this.getTemplateDetailList(true)
          type && (row.isEdit = false)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async deleteRow(row) {
      const columns = this.tableData.columns.filter(a => a.id)
      if (columns.length === 1) {
        this.$message.error('账单模板至少需要保留一条明细')
        return
      }
      try {
        const res = await delSupplierFeeIdentify({ id: row.id })
        if (res.success) {
          this.$message.success(res.errorMessage)
          this.getTemplateDetailList(true)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {
  border: 0;
}
/deep/ .el-dialog__body {
  padding-top: 0;
}
.content {
  width: 100%;
  .header {
    margin-bottom: 20px;
    .form-item {
      position: relative;
      .lable {
        display: block;
        width: 100%;
        height: 19px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin-bottom: 5px;
      }
      .red {
        color: rgba(255, 116, 68, 1);
      }
    }
  }
  .data {
    border-radius: 4px 4px 4px 4px;
    overflow: hidden;
    /deep/ input {
      border: 0;
    }
    /deep/ td {
      height: 40px;
      padding: 0;
    }
    .tab-opt {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      & > div {
        cursor: pointer;
      }
      & > img {
        cursor: pointer;
      }
    }
  }
}
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
